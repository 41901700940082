import {initializeSentry} from '../../assets/javascripts/modules/sentry';

// import cookieChoices from '@/components/layout/cookieChoices';

initializeSentry();

//     document.addEventListener('DOMContentLoaded', function () {
//         setTimeout(function () {
//             cookieChoices.displayCookies(window.termsUrl);
//         }, 3000);
//     });
